<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/exam' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('exam')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="exam"
                  ref="exam"
                  label-width="150px"
                  class="exam"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12" style="display: none">
                      <el-form-item label="id" prop="id">
                        <el-input v-model="exam.id"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="考试名称" prop="examName">
                        <el-input v-model="exam.examName"></el-input>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="排序" prop="seq'">
                        <el-input v-model="exam.seq"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!--  多功能 -->
            <!-- <ExamFunction></ExamFunction> -->

            <!-- 扩展-->
            <!-- <ExamExtends></ExamExtends> -->
            <div style="margin-left: 40px">
              <div class="f20">考试小节</div>
              <el-button
                type="primary"
                @click=" showDialog"
                style="margin-top: 20px"
                >新增
              </el-button>
            </div>
            <el-dialog
              title="新增考试小节"
              :visible.sync="dialogFormVisible"
              :close-on-click-modal="false"
              :close-on-press-escape="false"
              @closed="closed"
            >
              <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                  prop="examSubclassName"
                  label="小节名称"
                  :label-width="formLabelWidth"
                  style="margin-top: 15px"
                >
                  <el-input
                    v-model="form.examSubclassName"
                    autocomplete="off"
                    style="width: 300px"
                  >
                  </el-input>
                </el-form-item>
                <!-- <el-form-item
                  prop="content"
                  label="小节内容"
                  :label-width="formLabelWidth"
                  style="margin-top: 15px"
                >
                  <el-upload
                      class="upload-demo"
                      :multiple="false"
                      :action="$uploadURL"
                      :on-success="handleChange"
                      :on-progress="uploading"
                      :show-file-list="show"
                      :file-list="fileList"
                    >
                      <el-button size="small" type="text">{{
                        form.content
                      }}</el-button>
                    </el-upload>
                </el-form-item> -->
                      <el-form-item label="小节内容" prop="content"  :label-width="formLabelWidth" style="margin-top: 15px">
                        <quill-editor
                          ref="text"
                          v-model="form.content"
                            class="myQuillEditor"
                          style="padding:10px 10px 0px 0px"
                        />
                      </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" :disabled="disabledFlag" @click="addNewSpecifications"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
            <el-table :data="tableData" border style="width: 100%">
              <el-table-column type="index" prop="num" label="编号" width="180">
              </el-table-column>
              <el-table-column
                prop="examSubclassName"
                label="小节名称"
                width="180"
              >
              </el-table-column>
              <!-- <el-table-column prop="content" label="小节内容" width="180">
              </el-table-column> -->
              <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip
              class="item"
              effect="dark"
              content="编辑"
              placement="bottom"
            >
              <i
                class="el-icon-edit textblue f16"
                @click="gotoDetail(scope.row.examSubclassName,scope.row.content)"
              ></i>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="删除"
              placement="bottom"
            >
              <i
                class="el-icon-delete textblue f16 ml20"
               @click="del(scope.row.examSubclassName)"
              ></i>
            </el-tooltip>
          </template>
                </el-table-column>
            </el-table>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
// import ExamFunction from "@/views/exam/ExamFunction.vue";
// import ExamExtends from "@/views/exam/ExamExtends.vue";
export default {
  components: {
    // ExamFunction,
    // ExamExtends,
    quillEditor
  },
  data() {
    return {
      dialogFormVisible: false,
      //规格
      tableData: [],
      newName:"",
      form: {
        examSubclassName: "",
        content: "",
      },
      rules: {
        examSubclassName: [
          { required: true, message: "请输入小节名称", trigger: "blur" },
        ],
        
      },
      formLabelWidth: "120px",
      isShowLoad:false,
      disabledFlag:false,
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      exam: {
        id: "",
        createBy: "",
        createTime: "",
        updataBy: "",
        updataTime: "",
        examCode: "",
        examName: "",
        seq:0,
        examSubclassList:[],
      },
      examSubclassList:[],
    };
  },
  methods: {

    gotoDetail(examSubclassName,content){
      this.disabledFlag=false
      this.dialogFormVisible=true
      this.form.examSubclassName=examSubclassName;
      this.form.content=content;
      this.newName=examSubclassName;
    },
      //删除小节
    del(examSubclassName) {
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
            var newData=[];
      this.tableData.forEach((v)=>{
        if(v.examSubclassName!=examSubclassName){
              newData.push(v)
        }
      })
      this.tableData=newData;
          this.$message({
            type: "success",
            message: "删除成功!",
          });
           
        })
        .catch(() => {});
     
      // this.tableData.splice(index, 1);
    },
    closed() {
      this.form.examSubclassName = "";
      this.form.content = "";

      this.$refs["form"].clearValidate();
    },
    uploading(event, file, fileList) {
      this.show = true;
    },
    handleChange(response, file, fileList) {
      this.form.content = response.data.url;
      this.show = false;
      this.fileList = [];
    },
    //新增
    showDialog (){
       this.dialogFormVisible = true,
        this.disabledFlag=false
    },
    //添加小节
    addNewSpecifications() {
      this.disabledFlag=true
      // this.isShowLoad=true
      this.$refs["form"].validate(valid => {
        if (valid) {
          var list = {};
          if(this.newName!=''&&this.newName!=undefined){
            this.tableData.forEach((v=>{
              if(v.examSubclassName==this.newName){
                v.examSubclassName=this.form.examSubclassName;
                v.content=this.form.content;
              }
            }))
          
          }else{
           list.examSubclassName = this.form.examSubclassName;
          list.content = this.form.content;
          this.tableData.push(list);
          console.log("打印一下list",list)
          }
         this.dialogFormVisible = false;
        //  this.disabledFlag=false;
      
        } else {
        }
      });
    },
    //获取小节列表
    // getProductNormList() {
    //   let that = this;
    //   that.examCode=this.exam.examCode;
    //   that.$http
    //     .post("/examSubclass/list", that)
    //     .then(function(response) {
    //       if (response.data.code == 200) {
    //         that.examSubclassList = response.data.data;
    //         console.log("打印小节列表", that.examSubclassList);
    //       }
    //     });
    // },
    goIndex() {
      this.$router.push({
        path: "/exam",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      var that = this;
      var loginName2 = localStorage.getItem("loginName");
      var abc = JSON.parse(loginName2).value;
      this.exam.createBy = abc;
      that.exam.examSubclassList = that.tableData;
      console.log("--->发送数据： ",that.exam)
      that.$http
        .post("/exam/save", that.exam)
        .then(response => {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            })
             this.goIndex()
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        // .catch(function (error) {
        //   that.$notify.info({
        //     title: "提示",
        //     message: "保存失败",
        //     showClose: true,
        //   });
        // });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/exam/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              console.log("---->返回数据：",response.data);
              that.total = response.data.data.count;
              that.exam = response.data.data;
              that.tableData = response.data.data.examSubclassList;
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}

.quill-editor >.ql-toolbar > .ql-formats > .ql-video {
   display: none;
}

</style>
